<template>
    <v-app>
         <BandejaEntrada 
         v-on:pagina_anterior="page--" 
         v-on:pagina_siguiente="page++" 
         v-on:ver_documento="verDocumento" 
         :page="page" :tokens="tokens" 
         :folders="folders" 
         :folderPorPagina="folderPorPagina" 
         :todosLosFolder="todosLosFolder" 
         />
    </v-app>
</template>
<script>
import BandejaEntrada from '../../components/BandejaEntrada.vue'
import  bandeja_entrada from '../../mixins/bandeja_entrada'
const axios = require('axios').default
export default {
    mixins: [bandeja_entrada],
    components: {
        BandejaEntrada
    },
    data(){
        return {

        }
    },
    watch: {
        ruta(){
            this.iniciar()
        }
    },
    computed: {
        ruta(){
            return this.$route.params.id
        }
    },
    created(){
        this.iniciar()
    },
    methods: {
        verDocumento(documento) {
            console.log(documento);


            if (this.$route.params.id != documento.token) {
                this.$router.push({
                name: "informacion_caso_empleado",
                params: { id: documento.token, data: documento }
                });
            }
      },
      async iniciar() {
     
        this.tokens = [];
        this.folders = [];
        this.folderPorPagina = [];
        //Aqui ahora debo pasar un padre que indique que tokens voy a buscar
              this.$store.commit('auth','parse')
              console.log(this.$store.state.header)
              
              console.log(this.$store.state.dataUser.dataUser.sector)
              let buscar = JSON.stringify({"sector":this.$store.state.dataUser.dataUser.sector, "estado": this.ruta,
              "nombre_empleado": this.$store.state.dataUser.dataUser.nombre })

              buscar = encodeURIComponent(buscar)
              console.log(buscar)
      
              let response = await axios.get('https://audidoc.educandote.co/parse/classes/prueba01casosEmpleados?where='+buscar,
              {
                  headers:{
                          'Content-Type' : 'application/json',
                          'X-Parse-REST-API-Key': 'LASDK823JKHR87SDFJSDHF8DFHASFDF',
                          'X-Parse-Application-Id': 'KSDJFKASJFI3S8DSJFDH',
                          'Authorization': this.$store.state.header
                      }
              })
              console.log(response.data)
              let i = -1
              let obj 
              for (let index = 0; index < response.data.results.length; index++) {
                  obj = {
                  data: response.data.results[index],
                  title: response.data.results[index].nombre_documento,
                  subtitle: "Fecha limite de entrega: " + response.data.results[index].fecha_limite_supervisor,
                  remitente: response.data.results[index].nombre_supervisor,
                  token: response.data.results[index].token,
                  tipoDocumental: response.data.results[index].tipo_documental
                  } 
                  if (index < 5) {
                      this.folders.push(obj);
                  }
                  if (index % 5 == 0) {
                      i++;
                      this.folderPorPagina.push([]);
                  }
                  this.folderPorPagina[i].push(obj);
                  this.todosLosFolder.push(obj);
                  this.$store.commit("dataUser/SET_FOLDERS_USER", obj)
              }
      },
    }
}
</script>